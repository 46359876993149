<template>
	<nav class="sidebar-nav admin" v-if="clientNavItems">
		<p class="hidden-item grey">{{ getClientData.clientName }} Overview</p>
		<ul class="sidebar-panel-nav">
			<li class="item" :class="{disabled: item.visible == false}" v-for="item in clientNavItems" v-bind:key="item">
				<router-link :to="{path: item.href}">
					<i class="fa-duotone theme-main" v-bind:class="item.icon"></i>
					<span class="hidden-item">
						{{ item.title }}
					</span>
				</router-link>
			</li>
		</ul>
	</nav>
</template>
<script>
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			id: "clientNav",
			clientNavItems: [],
			hasGoogleToken : false,
			hasFacebookToken : false
		}
	},
	watch:{
        'getSocial.socialAccountInformation.google': {
        deep: true,
            handler() {

                this.loadTokens()

            }
        },
        'getSocial.socialAccountInformation.meta': {
        deep: true,
            handler() {

                this.loadTokens()
            }
        }
    },
	methods: {
		
		loadTokens(){
    
			if(Object.keys(this.getSocial.socialAccountInformation.google).length > 0){
				if(this.getSocial.socialAccountInformation.google.name && this.getSocial.socialAccountInformation.google.GA4PropertyName){
					this.hasGoogleToken = true;
				}else{
					this.hasGoogleToken = false;
				}
			}else{
				this.hasGoogleToken = false;
			}
			if(Object.keys(this.getSocial.socialAccountInformation.meta).length > 0){
				if(this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid && this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid){
					this.hasFacebookToken = true;
				}else{
					this.hasFacebookToken = false;
				}
			}else{
				this.hasFacebookToken = false;
			}

			let showGroup1 = false;
			let showGroup2 = false;
			let showGroup3 = false;
			let showGroup4 = false;

			const GA4PropertyID = this.getSocial.tokens.google.GA4PropertyID;
			const propertyID = this.getSocial.tokens.google.accountID;
			const googleAdsID = this.getSocial.tokens.google.adsAccountID;
			const agencyCampaign = this.getSocial.tokens.google.agencyCampaign;

			if(GA4PropertyID && propertyID && this.hasGoogleToken){

				showGroup1 = true;
			}
			if(googleAdsID && this.hasGoogleToken){

				showGroup2 = true;
			}
			if(GA4PropertyID && this.hasGoogleToken && this.hasFacebookToken){

				showGroup3 = true;
			}
			if(agencyCampaign && this.hasGoogleToken && this.getSocial.socialAccountInformation.google.seoValid){

				showGroup4 = true;
			}

			this.clientNavItems = [
			{href: `/client/${this.getClientData.slug}/dashboard`, title: "Dashboard", icon: "fa-grid-2", visible: true},
			// {href: `/client/${this.getAuthFirstAssignedClient}/content`, title: "Content", icon: "fa-quote-left", visible: this.getAuthFirstAssignedClient},
			{href: `/client/${this.getClientData.slug}/content-reach`, title: "Content Reach", icon: "fa-quote-left", visible: true},
			{href: `/client/${this.getClientData.slug}/paid-social`, title: "Paid Social", icon: "fa-cart-shopping", visible: showGroup1},
			{href: `/client/${this.getClientData.slug}/organic-social`, title: "Organic Social", icon: "fa-heart", visible: showGroup3},
			{href: `/client/${this.getClientData.slug}/analytics`, title: "Analytics", icon: "fa-chart-column", visible: showGroup1},
			{href: `/client/${this.getClientData.slug}/seo`, title: "SEO", icon: "fa-eye", visible: showGroup4},
			{href: `/client/${this.getClientData.slug}/ppc`, title: "PPC", icon: "fa-arrow-pointer", visible: showGroup2},
			]

		},

	},
	mounted() {
		
		this.loadTokens();
	
	},
	computed: {
		...mapGetters("client", ["getClientData", "getSocial"]),
		...mapGetters("auth", ["getAuthFirstAssignedClient"]),
		googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
		facebookData: {
            get() {
                return this.$store.getters["client/getClientFacebookData"] || ""
            },
        },
	},
}
</script>
