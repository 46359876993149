<template>
	<div class="user-actions">
		<nav class="actions">
			<ul>
				<template v-if="can(getAuthData, 'access_global_admin_view')">
					<!-- if is Admin -->
					<li class="action dashboard" v-if="$route.name === 'Client Content'">
						<router-link :to="{path: `/client/${getClientData.slug}/dashboard/`}">
							<i class="fa-duotone fa-grid-2 theme-main"></i>
							<span class="hidden-item">Dashboard</span>
						</router-link>
					</li>
					<li class="action" v-if="$route.path.includes('/client/')">
						<router-link :to="{path: `/admin/dashboard/`}">
							<i class="fa-duotone fa-user-group-crown theme-main"></i>
							<span class="hidden-item">Administration</span>
						</router-link>
					</li>
				</template>
				<template v-else>
					<li class="action" v-if="$route.name === 'Client Dashboard'">
						<router-link :to="{path: `/client/${getClientData.slug}/clients/`}">
							<i class="fa-duotone fa-gear theme-main"></i>
							<span class="hidden-item">Clients</span>
						</router-link>
					</li>
					<li class="action dashboard" v-else>
						<router-link :to="{path: `/client/${getClientData.slug}/dashboard/`}">
							<i class="fa-duotone fa-grid-2 theme-main"></i>
							<span class="hidden-item">Dashboard</span>
						</router-link>
					</li>
				</template>
				<li class="action integrations" v-if="$route.name !== 'Admin Dashboard' && $route.name !== 'Admin' && $route.name !== 'Admin Clients' && $route.name !== 'Edit Clients' && $route.name !== 'Admin User Management' && $route.name !== 'Edit Users' && $route.name !== 'Admin Role Management' && $route.name !== 'Edit Roles' && $route.name !== 'Admin Settings' && $route.name !== 'Edit User'">
					<div @click="this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})">
						<i class="fa-duotone fa-plug theme-main"></i>
						<span class="hidden-item">Integrations</span>
					</div>
				</li>
				<li class="action logout">
					<div @click="signOut">
						<i class="fa-duotone fa-arrow-right-from-bracket theme-main"></i>
						<span class="hidden-item">Logout</span>
					</div>
				</li>

			</ul>
		</nav>
	</div>
	<!-- end user-actions -->
</template>
<script>
//import axios from 'axios';
import {mapGetters} from "vuex"

export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters("auth", ["getAuthData", "getAuthRoleName", "getAuthRoleName"]),
		...mapGetters("client", ["getClientData"]),
		isAdmin() {
			return this.getAuthRoleName === "administrator" || this.getAuthRoleName === "operator"
		},
	},
	props: ["clientSlug"],
	methods: {
		async signOut() {
			await this.$store.dispatch("auth/logOut")
			this.$router.push("/login")
		},
	},
}
</script>
