<template>
    <nav class="sidebar-nav admin" v-if="adminNavItems">
        <p class="hidden-item grey">Overview</p>
        <ul class="sidebar-panel-nav">
            <template v-for="item in adminNavItems" v-bind:key="item">
                <li class="item" v-if="can(getAuthData, item.permission) || !item.permission">
                    <router-link :to="item.href">
                        <i class="fa-duotone theme-main" v-bind:class="item.icon"></i>
                        <span class="hidden-item"> 
                            {{ item.title }}
                        </span>
                    </router-link>
                </li>
            </template>
        </ul>
    </nav>
</template>
<script>
    import {mapGetters} from 'vuex';
    export default {
        data() {
            return {
                adminNavItems: [
                    { href: '/admin/dashboard', title: 'Dashboard', permission:'access_global_admin_view', icon: 'fa-grid-2' },
                    { href: '/admin/clients', title: 'Clients', icon: 'fa-user-tie' },
                    { href: '/admin/user-management', title: 'User Management', permission:'access_global_admin_view', icon: 'fa-users' },
                    { href: '/admin/role-management', title: 'Role Management', permission:'do_everything', icon: 'fa-user-crown' },
                ]
            }   
        },
        computed: {
            ...mapGetters('auth', ['getAuthData'])
        }
    }
</script>